import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './translations/locales/en.json'
import translationHE from './translations/locales/he.json'

const resources = {
    en: {
        translation: translationEN
    },
    he: {
        translation: translationHE
    }
};

i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources,
        lng: 'he',
        fallbackLng: 'he',
        debug: true,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });


export default i18n;