import { Direction } from "@mui/material";
import {
  enUS as datePickerLocaleEnglish,
  heIL as datePickerLocaleHebrew
} from "@mui/x-date-pickers";
import {
  enUS as materialLocaleEnglish,
  heIL as materialLocaleHebrew,
  Localization,
} from "@mui/material/locale";

import "dayjs/locale/en";
import "dayjs/locale/he";

export interface MUILocaleData {
  muiCore: Localization;
  muiDatePicker: any;
  dayJSLanguage: string;
  title: string;
  direction: Direction;
  font: string;
  localeName: string;
}

const english: MUILocaleData = {
  muiCore: materialLocaleEnglish,
  muiDatePicker: datePickerLocaleEnglish,
  dayJSLanguage: "en",
  title: "English",
  direction: "ltr",
  font: 'Poppins, sans-serif',
  localeName: "en_US",
};


const hebrew: MUILocaleData = {
  muiCore: materialLocaleHebrew,
  muiDatePicker: datePickerLocaleHebrew,
  dayJSLanguage: "he",
  title: "Hebrew",
  direction: "rtl",
  font: 'Rubik, sans-serif',
  localeName: "he_IL",
};

export const supportedLocales = {
  english,
  hebrew,
};
