import { QuestionFileModel } from "survey-core";
import agent from "../../app/api/agent";
import { useTranslation } from "react-i18next";

interface UploadFilesOptions {
    question: QuestionFileModel;
    name: string;
    callback: (data: any[], errors?: string[]) => void;
    files: File[];
}

const useTakeSurvey = () => {
    const { t } = useTranslation();

    const handleFileClear = async (options: any, surveyResponseId?: string) => {
        if (options.fileName) {
            try {
                await agent.Media.deleteFile(options.fileName);
                options.callback("success");
            } catch (error) {
                console.error("Error deleting file:", error);
                options.callback("error");
            }

            return;
        }

        if (!surveyResponseId) {
            options.callback("error");
            return;
        }

        try {
            await agent.Media.deleteAllFilesForResponse(surveyResponseId);
            options.callback("success");
        } catch (error) {
            console.error("Error deleting all files:", error);
            options.callback("error");
        }
    };

    const handleFileUpload = async (options: UploadFilesOptions) => {
        const formData = new FormData();
        options.files.forEach((file: File) => {
            formData.append("files", file, file.name);
        });

        try {
            const response = await agent.Media.uploadFiles(formData);
            const fileUrls = response.map((file) => ({
                file: file.original_filename,
                content: file.secure_url,
            }));
            options.callback(fileUrls);
        } catch (error) {
            options.callback([], [t("media.error.upload")]);
        }
    };

    return {
        handleFileClear,
        handleFileUpload,
    }
}

export default useTakeSurvey;