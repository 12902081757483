import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { StoreContext, store } from "./app/stores/store";
import "./i18n";
import MUIWrapper from "./components/common/MUIWrapper";
import { CookiesProvider } from "react-cookie";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <React.StrictMode>
        <CookiesProvider>
            <StoreContext.Provider value={store}>
                <MUIWrapper>
                    <App />
                </MUIWrapper>
            </StoreContext.Provider>
        </CookiesProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
