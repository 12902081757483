import { makeAutoObservable, runInAction } from "mobx";
import { SurveyResponse, SurveyResponseStatus, SurveyResponseCreate } from "../models/surveyResponse";
import agent from "../api/agent";
import { Coupon } from "../models/Coupon";

export default class SurveyResponseStore {
    surveyResponse: SurveyResponse | null = null;
    coupon: Coupon | null = null;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this);
    }

    // This method will be used to load a specific survey response.
    loadSurveyResponse = async (responseId: string) => {
        try {
            const surveyResponse = await agent.SurveysResponses.get(responseId, true);
            this.setSurveyResponse(surveyResponse);
            return surveyResponse;
        } catch (error) {
            console.log(error);
        }
    }

    updateSurveyResponseStatus = async (responseId: string, status: SurveyResponseStatus) => {
        try {
            await agent.SurveysResponses.updateStatus(responseId, status);
            runInAction(() => {
                if (!this.surveyResponse) {
                    return;
                }

                this.setSurveyResponse({ ...this.surveyResponse!, status });
            });
        } catch (error) {
            console.log(error);
        }
    }

    updateSurveyResponse = async (response: Partial<SurveyResponse>) => {
        try {
            const { surveyResponse, workflowResult } = await agent.SurveysResponses.update(response);
            const updatedResponse = { ...this.surveyResponse!, ...surveyResponse };

            this.setSurveyResponse(updatedResponse);
            if (workflowResult.coupon) {
                this.setCoupon((workflowResult.coupon as Coupon) || null);
            }
        } catch (error) {
            console.log(error);
        }
    }


    createSurveyResponse = async (surveyId: number, response: SurveyResponseCreate) => {
        try {
            const { surveyResponse, workflowResult } = await agent.SurveysResponses.create(surveyId, response);

            this.setSurveyResponse(surveyResponse);
            if (workflowResult.coupon) {
                this.setCoupon((workflowResult.coupon as Coupon) || null);
            }
        } catch (error) {
            console.log(error);
        }
    }

    setSurveyResponse = (response: SurveyResponse | null) => {
        this.surveyResponse = response;
    }

    setCoupon = (coupon: Coupon | null) => {
        this.coupon = coupon;
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

}