import { createContext, useContext } from "react";
import SurveyStore from "./surveyStore";
import SurveyResponseStore from "./surveyResponseStore";
import CollectorStore from "./collectorStore";
import CommonStore from "./commonStore";

interface Store {
    surveyStore: SurveyStore;
    surveyResponseStore: SurveyResponseStore;
    collectorStore: CollectorStore;
    commonStore: CommonStore;
}

export const store: Store = {
    surveyStore: new SurveyStore(),
    surveyResponseStore: new SurveyResponseStore(),
    collectorStore: new CollectorStore(),
    commonStore: new CommonStore()
}

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}