import { ItemValue, Question, SurveyModel } from "survey-core";
import { Collector, DynamicParameters } from '../app/models/collector';
import { Contact } from "../app/models/Contact";
import { getPlaceholderValues } from "./helpers";
import { QUESTION_TYPES, PlaceholderKey } from "./const";
import { Coupon } from "../app/models/Coupon";
import { Survey } from "../app/models/survey";


export const calculateMaxScore = (questions: Question[]) => {
    let maxScore = 0;

    questions.forEach((question) => {
        const questionType = question.getType();

        if (question.choices) {
            maxScore += questionType === QUESTION_TYPES.RADIO_GROUP ? getRadioGroupMaxScore(question) : getChecboxMaxScore(question);
        }
        if (question.rateValues) {
            if (question?.rateMax) {
                maxScore += question.rateMax;
            } else if (question?.autoGenerate === false) {
                maxScore += question.rateValues.length;
            } else {
                const maxValue = Math.max(...question.rateValues.map((o: ItemValue) => o.score || 0));
                maxScore += maxValue;
            }
        }
        if (questionType === QUESTION_TYPES.MATRIX) {
            const maxMatrixValue = Math.max(...question.columns.map((o: ItemValue) => o.score || 0)) * question.rows.length;
            maxScore += maxMatrixValue;
        }
    });
    return maxScore;
};

export const calculateTotalScore = (data: any[], survey: SurveyModel) => {
    let totalScore = 0;
    data.forEach((item) => {
        const question = survey.getQuestionByValueName(item.name);
        const questionType = question.getType();

        const qValue = item.value;
        if (question.choices) {
            totalScore += questionType === QUESTION_TYPES.RADIO_GROUP ? getRadioGroupTotalScore(question, qValue) : getCheckboxTotalScore(question, qValue);
        }
        if (question.rateValues) {
            const selectedRate = question.rateValues.find((rate: ItemValue) => rate.value === qValue)
            if (selectedRate && selectedRate.score) {
                totalScore += selectedRate.score;
            } else {
                totalScore += qValue;
            }
        }
        if (questionType === QUESTION_TYPES.MATRIX) {
            item.data.forEach((dataItem: { score: number }) => {
                if (dataItem.score) {
                    totalScore += dataItem.score;
                }
            });
        }
    });
    return totalScore;
};

const getRadioGroupMaxScore = (question: Question) => {
    return Math.max(...question.choices.map((o: ItemValue) => o.score || 0));
}

const getChecboxMaxScore = (question: Question) => {
    return [...question.choices].reduce((acc, choice) => {
        return acc + (choice.score || 0);
    }, 0)
}

const getRadioGroupTotalScore = (question: Question, value: string) => {
    const selectedChoice = question.choices.find((choice: ItemValue) => choice.value === value);
    return selectedChoice?.score || 0;
}

const getCheckboxTotalScore = (question: Question, value: string[]) => {
    const selectedChoices = question.choices.filter((choice: ItemValue) => value.includes(choice.value));
    return selectedChoices.reduce((acc: number, choice: any) => {
        return acc + (choice.score || 0);
    }, 0)
}

export const setDynamicParameters = (model: SurveyModel, dynamicParameters: DynamicParameters) => {
    Object.entries(dynamicParameters).forEach(([key, value]) => {
        model.setVariable(key, value);
    });
};


export const mergeAndReplacePlaceholders = (
    collectorParams: DynamicParameters | null,
    responseParams: DynamicParameters | null,
    contact: Contact | null,
    coupon: Coupon | null,
    collector: Collector,
    survey: Survey,
    responseId: string | null,
): DynamicParameters => {
    const placeholderValues = getPlaceholderValues(contact, coupon, collector, survey, responseId);

    const finalParams: DynamicParameters = { ...(responseParams || {}) };

    if (collectorParams) {
        Object.entries(collectorParams).forEach(([key, value]) => {
            if (!(key in finalParams)) {
                if (
                    typeof value === 'string' &&
                    (value as string) in placeholderValues
                ) {
                    finalParams[key] = placeholderValues[value as PlaceholderKey];
                } else {
                    finalParams[key] = value;
                }
            }
        });
    }

    return finalParams;
};
