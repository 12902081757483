export type DynamicParameters = Record<string, string>

export interface Collector {
    id: string;
    surveyId: number;
    type: CollectorType;
    status: CollectorStatus;
    name: string;
    surveyTitle: string;
    completedResponsesCount: number;
    responseLimit: number | null;
    closedMessage: string;
    openDate: Date | null;
    senderId: string;
    groupIds: number[];
    closeDate: Date | null;
    limitByIp: boolean;
    limitByCookie: boolean;
    dynamicParameters: DynamicParameters | null;
    createdAt: Date;
    updatedAt: Date;
}

export enum CollectorType {
    SMS = 'SMS',
    EMAIL = 'EMAIL',
    WEB_LINK = 'WEB_LINK'
}

export enum CollectorStatus {
    OPEN = 'OPEN',
    CLOSED = 'CLOSED',
    DRAFT = 'DRAFT',
    DELETED = 'DELETED',
    SCHEDULED = "SCHEDULED",
    RESPONSE_LIMIT_EXCEEDED = "RESPONSE_LIMIT_EXCEEDED"
}