import { makeAutoObservable } from "mobx";
import { SnackbarMessage } from "../core/models/snackbarMessage";
import agent from "../api/agent";
import axios from '../api/axiosConfig'

export default class CommonStore {
    message: SnackbarMessage | null = null;
    fetchingToken: boolean = true;
    token: string | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    loadToken = async (collectorId?: string, surveyResponseId?: string) => {
        this.setFetchingToken(true);
        try {
            const searchParams = new URLSearchParams();
            if (collectorId) {
                searchParams.append('collector_id', collectorId)
            };
            if (surveyResponseId) {
                searchParams.append('survey_response_id', surveyResponseId)
            };

            const tokenResponse = await agent.Auth.getSurveyToken(searchParams);
            this.setToken(tokenResponse);

            axios.defaults.headers.common['Authorization'] = `Bearer ${tokenResponse}`;

            return tokenResponse;
        } catch (error) {
            throw error;
        }
        finally {
            this.setFetchingToken(false);
        }

    }

    setMessage = (message: SnackbarMessage | null) => {
        this.message = message;
    }

    setToken = (token: string | null) => {
        this.token = token;
    }

    setFetchingToken = (fetching: boolean) => {
        this.fetchingToken = fetching;
    }

}