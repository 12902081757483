import { makeAutoObservable } from 'mobx';
import { Collector, CollectorStatus } from '../models/collector';

import agent from '../api/agent';

export default class CollectorStore {
    collectorRegistry = new Map<string, Collector>();
    selectedCollector: Collector | undefined = undefined;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this);
    }


    loadCollector = async (id: string): Promise<Collector> => {
        let collector = this.getCollector(id);
        if (collector) {
            this.setSelectedCollector(collector);
            return collector;
        } else {
            this.setLoadingInitial(true);
            try {
                const fetchedCollector = await agent.Collectors.getById(id);
                this.setCollector(fetchedCollector);
                this.setSelectedCollector(fetchedCollector);
                return fetchedCollector;
            } catch (error) {
                console.log(error);
                throw error;
            } finally {
                this.setLoadingInitial(false);
            }
        }
    }

    setLoadingInitial = (state: boolean): void => {
        this.loadingInitial = state;
    }

    setCollector = (collector: Collector): void => {
        this.collectorRegistry.set(collector.id, collector);
    }

    getCollector = (id: string): Collector | undefined => {
        return this.collectorRegistry.get(id);
    }

    setSelectedCollector = (collector: Collector): void => {
        this.selectedCollector = collector;
    }

}