import { makeAutoObservable, runInAction } from "mobx";
import { Survey, SurveyStatus } from "../models/survey";
import agent from "../api/agent";

export default class SurveyStore {
    surveys: Survey[] = [];
    selectedSurvey: Survey | null = null;
    loadingSurveys: boolean = false;
    loadingSurvey: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }


    loadSelectedSurvey = async (id: number) => {
        if (this.selectedSurvey?.id === id) {
            return this.selectedSurvey;
        }

        this.setLoadingSurvey(true);
        try {
            const survey = await agent.Surveys.getById(id);
            this.setSelectedSurvey(survey);
            return survey;
        } catch (err) {
            console.error(err);
        } finally {
            this.setLoadingSurvey(false);
        }
    };


    setSelectedSurvey = (survey: Survey | null) => {
        this.selectedSurvey = survey;
    }


    setLoadingSurvey = (loading: boolean) => {
        this.loadingSurvey = loading;
    }

    getSurvey = (id: number) => {
        return this.surveys.find(survey => survey.id === id);
    }

}
