import { createTheme, ThemeProvider, PaletteMode, alpha, useMediaQuery } from "@mui/material";
import { createContext, useMemo, useState, useEffect } from "react";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MUILocaleData, supportedLocales } from "./SupportedLocales";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import palette from "./themeOverrides/palette";
import shadows, { customShadows } from "./themeOverrides/shadows";
import themeOverrides from "./themeOverrides";


export const MUIWrapperContext = createContext({
    toggleColorMode: () => { },
    locale: supportedLocales.hebrew,
});

// Create rtl cache
const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, rtlPlugin],
});

const emptyCache = createCache({
    key: "meaningless-key",
});

// TODO: When want to implement dark mode or system preference, do it here.
const getPaleteMode = (prefersDarkMode: boolean): PaletteMode => {
    return "light";
}

export default observer(function MUIWrapper({
    children,
}: {
    children: React.ReactNode;
}) {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const { i18n: { language: i18nLang } } = useTranslation();
    const [mode, setMode] = useState<PaletteMode>(getPaleteMode(prefersDarkMode));
    const [locale, setLocale] = useState<MUILocaleData>(supportedLocales.hebrew);
    const muiWrapperUtils = useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
            },
        }),
        []
    );

    useEffect(() => {
        document.dir = locale.direction;
    }, [locale.direction]);

    useEffect(() => {
        if (i18nLang === 'he') {
            setLocale(supportedLocales.hebrew)
        } else {
            setLocale(supportedLocales.english)
        }
    }, [i18nLang])

    const theme = useMemo(
        () => {
            const muiTheme = (
                createTheme({
                    direction: locale.direction,
                    palette: mode === 'light' ? palette.light : palette.dark,
                    shadows:  mode === 'light' ? shadows.light : shadows.dark,
                    customShadows: mode === 'light' ? customShadows.light : customShadows.dark,

                    shape: {
                        borderRadius: 8
                    },
                    typography: {
                        fontFamily: locale.font,
                        button: {
                            textTransform: 'none',
                        },
                    },
                    components: {
                        MuiOutlinedInput: {
                            styleOverrides: {
                                root: {
                                    '& fieldset': {
                                        transition: "border-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                                    },
                                    "&.Mui-focused": {
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            transition: "border-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                                        },
                                    },

                                }
                            }
                        },
                        MuiPaper: {
                            defaultProps: {
                                elevation: 0,
                            }
                        },
                        MuiTouchRipple: {
                            styleOverrides: {
                                rippleVisible: {
                                    transitionDuration: '10ms'
                                },
                                ripplePulsate: {
                                    transitionDuration: '10ms'
                                }
                            }
                        },
                    }
                },
                    locale.muiCore,
                    locale.muiDatePicker
                )
            )
            muiTheme.shadows[23] = `${alpha(muiTheme.palette.primary.main, .25)} 0px 8px 16px 0px`
            return muiTheme;
        }
        , [mode, locale]
    );

    theme.components = themeOverrides(theme);

    return (
        <CacheProvider value={locale.direction === "rtl" ? cacheRtl : emptyCache}>
            <MUIWrapperContext.Provider
                value={{
                    toggleColorMode: muiWrapperUtils.toggleColorMode,
                    locale,
                }}
            >
                <ThemeProvider theme={theme}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={locale.dayJSLanguage}
                    >
                        {children}
                    </LocalizationProvider>
                </ThemeProvider>
            </MUIWrapperContext.Provider>
        </CacheProvider>
    );
})
