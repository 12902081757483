import { PLACEHOLDERS, PlaceholderKey } from './const';
import { Contact } from '../app/models/Contact';
import { Coupon } from '../app/models/Coupon';
import DOMPurify from 'dompurify';
import { Collector } from '../app/models/collector';
import { Survey } from '../app/models/survey';
import i18n from '../i18n';
const { t } = i18n;

export const getContactFullName = (contact: Contact): string => {
    const { firstName, lastName } = contact;
    if (firstName && lastName) {
        return `${firstName} ${lastName}`;
    }
    return firstName || lastName || "";
};

export const getSurveyUrl = (responseId: string): string => {
    return `${window.location.origin}/survey/${responseId}`;
};

export const getPlaceholderValues = (contact: Contact | null, coupon: Coupon | null, collector: Collector, survey: Survey, responseId: string | null): Record<PlaceholderKey, string> => {
    return {
        [PLACEHOLDERS.CONTACT_IDENTIFIER]: contact?.externalId || "",
        [PLACEHOLDERS.CONTACT_FIRST_NAME]: contact?.firstName || "",
        [PLACEHOLDERS.CONTACT_LAST_NAME]: contact?.lastName || "",
        [PLACEHOLDERS.CONTACT_EMAIL]: contact?.email || "",
        [PLACEHOLDERS.CONTACT_PHONE]: contact?.phone || "",
        [PLACEHOLDERS.CONTACT_FULL_NAME]: contact ? getContactFullName(contact) : "",
        [PLACEHOLDERS.SURVEY_URL]: responseId ? getSurveyUrl(responseId) : "",
        [PLACEHOLDERS.COUPON_CODE]: coupon?.code || "",
        [PLACEHOLDERS.COUPON_EXPIRATION_DATE]: coupon?.expiration_date ? new Date(coupon.expiration_date).toLocaleDateString() : t("common.notFound"),
        [PLACEHOLDERS.COUPON_DESCRIPTION]: coupon?.group?.description || "",
        [PLACEHOLDERS.COLLECTOR_ID]: collector.id,
        [PLACEHOLDERS.COLLECTOR_NAME]: collector.name,
        [PLACEHOLDERS.SURVEY_TITLE]: survey.title,
    };
};


export const replaceHandlebars = (template: string, replacements: Record<string, string>): string => {
    return template.replace(/{{\s*(.*?)\s*}}/g, (match, key) => {
        return replacements[key] || '';
    });
};


/**
 * Sanitizes HTML content using DOMPurify with predefined settings.
 * @param {string} html - The HTML content to sanitize.
 * @returns {string} - The sanitized HTML content.
 */
export function sanitizeHtml(html: string): string {
    return DOMPurify.sanitize(html, {
        USE_PROFILES: { html: true },
        ALLOWED_TAGS: [
            'b', 'i', 'em', 'strong', 'a', 'p', 'ul', 'ol', 'li', 'br',
            'span', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'img',
        ],
        ALLOWED_ATTR: [
            'href', 'target', 'rel', 'class', 'style', 'data-parameter',
            'src', 'alt', 'title', 'width', 'height',
        ],
        ALLOW_DATA_ATTR: false,
        FORBID_TAGS: ['script', 'style', 'iframe'],
        FORBID_ATTR: ['onerror', 'onclick', 'onload'],
    });
}
