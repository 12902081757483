import { DynamicParameters } from './collector';
import { Contact } from './Contact';

export type SurveyMethod = 'sms' | 'mail' | 'web';

export interface SurveyResponse {
    id: string;
    surveyId: number;
    method: SurveyMethod;
    expire_in?: string;
    status: SurveyResponseStatus;
    content: object;
    dynamicParameters: DynamicParameters | null;
    maxScore: number | null;
    totalScore: number | null;
    duration: number | null;
    collectorId: string;
    contactId: number | null;
    contact: Contact | null;
    createdAt: string;
    updatedAt: string;
}

export interface SurveyResponseCreate {
    status: SurveyResponseStatus;
    method: SurveyMethod;
    expire_in?: string;
    content: object;
    collectorId: string;
    surveyId: number;
    maxScore: number;
    totalScore: number;
    duration: number;
}


export enum SurveyResponseStatus {
    CREATED = 'CREATED', // The survey response has been created but not started yet.
    IN_PROGRESS = 'IN_PROGRESS', // The respondent has started the survey but has not completed it.
    COMPLETED = 'COMPLETED', // The respondent has finished the survey.
    PENDING_REVIEW = 'PENDING_REVIEW', // The survey response is awaiting review or approval.
    REVIEWED = 'REVIEWED', // The survey response has been reviewed and approved.
    ARCHIVED = 'ARCHIVED', // The survey response has been archived for historical reference.
    CANCELLED = 'CANCELLED', // The survey response has been cancelled before completion.
    EXPIRED = 'EXPIRED' // The survey response has expired, possibly due to a time limit or inactivity.
}