import { Stack, Typography } from "@mui/material";
import React from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useTranslation } from "react-i18next";

const ServerErrorPage = () => {
    const { t } = useTranslation();

    return (
        <Stack
            alignItems="center"
            justifyContent="center"
            height="100vh"
            spacing={1}
            sx={{
                color: (theme) => theme.palette.error.main,
            }}
        >
            <ErrorOutlineIcon sx={{ fontSize: "204px" }} />
            <Typography variant="h1" fontWeight="medium">
                500
            </Typography>
            <Typography
                variant="h4"
                sx={{
                    color: (theme) => theme.palette.action.disabled,
                }}
            >
                {t("serverErrorPage.title", "Server Error")}
            </Typography>
            <Typography
                variant="h6"
                sx={{
                    maxWidth: "400px",
                    textAlign: "center",
                }}
            >
                {t("serverErrorPage.subtitle", "Something went wrong. Please try again later.")}
            </Typography>
        </Stack>
    );
};

export default ServerErrorPage;
