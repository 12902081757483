import axios, { AxiosError, AxiosResponse } from 'axios'

import { APIError } from './errors';

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.defaults.withCredentials = true;
axios.interceptors.response.use(async response => {
    if (process.env.NODE_ENV === 'development' || !process.env.NODE_ENV)
        await sleep(200);

    return response;

}, (error: AxiosError) => {
    const { data, status } = error.response as AxiosResponse;

    switch (status) {
        case 400:
            break;
        case 401:
            throw {
                status: status,
                detail: data.detail
            } as APIError;
        case 422:
            break
        case 403:
            break;
        case 404:
            break;
        case 413:
            break;
        case 429:
            break;
        case 500:
            break;
        default:
            throw data;
    }
    return Promise.reject(error);
}
)

axios.interceptors.request.use(config => {
    if (!config.url) {
        return config;
    }

    let baseURL = window.origin + '/api/v1';
    if (config.baseURL?.includes('http')) {
        baseURL = config.baseURL
    }

    const url = config.url ? new URL(baseURL + config.url) : null;
    const queryParams = url ? Object.fromEntries(url.searchParams) : {};

    const areQueryParams = Object.keys(queryParams).length > 0;
    const addSlash = config.url?.split("/").length <= 2 && !areQueryParams

    config.url = addSlash ? config.url + '/' : config.url

    config.headers.locale = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return config;
});

export default axios;