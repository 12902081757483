import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { useStore } from '../app/stores/store';
import { observer } from 'mobx-react-lite';

interface Props {
  message: string;
}

const SurveyMessage = ({ message }: Props) => {
  const { surveyStore } = useStore();
  const { selectedSurvey } = surveyStore;

  const logoURL = selectedSurvey?.content?.logo;

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={3}
      sx={{ height: "100vh", width: "100vw" }}
    >
      {logoURL ? (
        <Box
          component="img"
          src={logoURL}
          alt="logo"
          sx={{ width: "250px", height: "auto" }}
        />
      ) : null}
      <Typography variant="h4" align="center">
        {message}
      </Typography>
    </Stack>
  )
}

export default observer(SurveyMessage);