import { Theme } from '@mui/material/styles';
//

import Button from './Button';
import Paper from './Paper';
import CssBaseline from './CssBaseline'
import Popover from './Popover'
import Skeleton from './Skeleton'
import Autocomplete from './Autocomplete'
import Card from './Card'
import Menu from './Menu'
import Tooltip from './Tooltip'
import Popper from './Popper'
import Switch from './Switch'
// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme: Theme) {
  return Object.assign(
    Button(theme),
    Paper(theme),
    CssBaseline(theme),
    Popover(theme),
    Skeleton(theme),
    Autocomplete(theme),
    Card(theme),
    Menu(theme),
    Tooltip(theme),
    Popper(theme),
    Switch(theme),
  );
}
