import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Model, SurveyModel } from 'survey-core';
import { Survey as SurveyComponent } from 'survey-react-ui';
import { useStore } from './../app/stores/store';
import { SurveyResponse, SurveyResponseStatus } from './../app/models/surveyResponse';
import { CollectorStatus } from '../app/models/collector';
import { calculateMaxScore, calculateTotalScore, mergeAndReplacePlaceholders, setDynamicParameters } from './utils';
import ServerErrorPage from './common/ServerErrorPage';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import useTakeSurvey from './hooks/useTakeSurvey';
import SurveyMessage from './SurveyMessage';
import CouponMessage from './CouponMessage';
import 'survey-core/defaultV2.min.css';
import 'survey-core/i18n/hebrew';
import useMesaureTime from '../app/hooks/useMeasureTime';

const HEBREW_LOCALE = 'he';

const TakeSurvey = () => {
    const { id: responseId } = useParams<{ id?: string }>();
    const { handleFileClear, handleFileUpload } = useTakeSurvey();
    const { startMeasure, endMeasure } = useMesaureTime();
    const [initialSurveyStatus, setInitialSurveyStatus] = useState<SurveyResponseStatus | null>(null);
    const [hasServerError, setHasServerError] = useState<boolean>(false);
    const { surveyResponseStore, surveyStore, commonStore, collectorStore } = useStore();
    const { loadCollector, selectedCollector } = collectorStore;
    const { loadSelectedSurvey, selectedSurvey } = surveyStore;
    const { loadToken } = commonStore;
    const { t } = useTranslation();

    const { setMessage } = commonStore;
    const { coupon, surveyResponse, loadSurveyResponse, updateSurveyResponseStatus } = surveyResponseStore;

    const fetchSurveyData = async () => {
        await loadToken(undefined, responseId);
        if (!responseId) {
            setMessage({ message: t('takeSurvey.errors.surveyMissing'), type: 'error' });
            return;
        }
        try {
            const surveyResponse = await loadSurveyResponse(responseId);
            if (!surveyResponse) {
                setMessage({ message: t('takeSurvey.errors.surveyMissing'), type: 'error' });
                return;
            }
            setInitialSurveyStatus(surveyResponse.status);

            const collector = await loadCollector(surveyResponse.collectorId);
            if (!collector) {
                setMessage({ message: t('takeSurvey.errors.surveyMissing'), type: 'error' });
                return;
            }

            if (surveyResponse.status === SurveyResponseStatus.CREATED) {
                updateSurveyResponseStatus(responseId, SurveyResponseStatus.IN_PROGRESS);
            }

            const survey = await loadSelectedSurvey(surveyResponse.surveyId);
            if (!survey) {
                setMessage({ message: t('takeSurvey.errors.surveyMissing'), type: 'error' });
                return;
            }

            startMeasure();
        } catch (error) {
            setHasServerError(true);
            setMessage({ message: t('takeSurvey.errors.surveyDataFetchError'), type: 'error' });
        }
    };

    useEffect(() => {
        fetchSurveyData();
    }, []);

    const handleSurveyComplete = async (sender: SurveyModel) => {
        if (!responseId) {
            setMessage({ message: t('takeSurvey.errors.surveyMissing'), type: 'error' });
            return;
        }

        const duration = endMeasure('second') || 0;

        const plainData = sender.getPlainData({
            calculations: [{ propertyName: 'score' }],
        });
        const maxScore = calculateMaxScore(sender.getAllQuestions());
        const totalScore = calculateTotalScore(plainData, sender);

        sender.setValue('maxScore', maxScore);
        sender.setValue('totalScore', totalScore);

        try {
            const response: Partial<SurveyResponse> = { id: surveyResponse!.id, status: SurveyResponseStatus.COMPLETED, content: sender.data, maxScore, totalScore, duration };

            await surveyResponseStore.updateSurveyResponse(response);
        } catch (error) {
            setHasServerError(true);
            setMessage({ message: t('takeSurvey.errors.surveyResponseUpdateError'), type: 'error' });
        }
    };

    const surveyModel = useMemo(() => {
        if (!selectedSurvey?.content || !selectedCollector || !surveyResponse) {
            return null;
        }

        const model = new Model(selectedSurvey.content);
        setDynamicParameters(
            model,
            mergeAndReplacePlaceholders(selectedCollector.dynamicParameters, surveyResponse.dynamicParameters, surveyResponse.contact!, null, selectedCollector, selectedSurvey, surveyResponse.id),
        );

        if (selectedSurvey?.theme) {
            model.applyTheme(selectedSurvey.theme.theme);
        }

        return model;
    }, [selectedSurvey]);

    if (hasServerError) {
        return <ServerErrorPage />;
    }

    if (coupon) {
        return <CouponMessage coupon={coupon} />;
    }

    if (initialSurveyStatus === SurveyResponseStatus.COMPLETED) {
        return <SurveyMessage message={t('takeSurvey.surveyFilled')} />;
    }

    if (selectedCollector && selectedCollector.status === CollectorStatus.CLOSED) {
        return <SurveyMessage message={selectedCollector.closedMessage} />;
    }

    return (
        <>
            {selectedSurvey?.content && (
                <SurveyComponent
                    model={surveyModel}
                    onCompleting={handleSurveyComplete}
                    onUploadFiles={(_: Model, options: any) => handleFileUpload(options)}
                    onClearFiles={(_: SurveyModel, options: any) => handleFileClear(options, surveyResponse?.id)}
                    locale={selectedSurvey.content?.locale || HEBREW_LOCALE}
                />
            )}
        </>
    );
};

export default observer(TakeSurvey);
