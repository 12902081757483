import { Stack, Typography } from '@mui/material'
import React from 'react'
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import { useTranslation } from 'react-i18next';

const NotFoundPage = () => {
    const { t } = useTranslation();

    return (
        <Stack alignItems='center' justifyContent='center' height='100vh' spacing={1}
            sx={{
                color: theme => theme.palette.action.active
            }}>
            <SentimentDissatisfiedIcon sx={{ fontSize: '204px' }} />
            <Typography variant='h1' fontWeight='medium'>404</Typography>
            <Typography variant='h4'
                sx={{
                    color: theme => theme.palette.action.disabled
                }}>
                {t('notFoundPage.title')}
            </Typography>
            <Typography variant='h6' sx={{
                maxWidth: '400px',
                textAlign: 'center'
            }}>
                {t('notFoundPage.subtitle')}
            </Typography>
        </Stack>
    )
}

export default NotFoundPage