import React from 'react';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { useStore } from '../app/stores/store';
import { observer } from 'mobx-react-lite';
import { Coupon } from '../app/models/Coupon';
import { useTranslation } from 'react-i18next';
import { sanitizeHtml } from './helpers';
import { getPlaceholderValues, replaceHandlebars } from './helpers';

interface Props {
    coupon: Coupon;
}

const CouponMessage = ({ coupon }: Props) => {
    const { t } = useTranslation();

    const { surveyStore, surveyResponseStore, collectorStore } = useStore();
    const { selectedSurvey } = surveyStore;
    const { surveyResponse } = surveyResponseStore;
    const { selectedCollector } = collectorStore;

    const logoURL = selectedSurvey?.content?.logo;

    const contact = surveyResponse?.contact || null;
    const responseId: string = surveyResponse?.id || '';

    const placeholderValues = getPlaceholderValues(contact, coupon, selectedCollector!, selectedSurvey!, responseId);
    const replacedCouponMessage = coupon.coupon_message ? replaceHandlebars(coupon.coupon_message, placeholderValues) : '';
    const sanitizedCouponMessage = replacedCouponMessage ? sanitizeHtml(replacedCouponMessage) : '';

    return (
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={1} sx={{ height: '100vh', width: '100vw' }}>
            {logoURL ? <Box component="img" src={logoURL} alt="logo" sx={{ width: '250px', height: 'auto' }} /> : null}
            <Paper elevation={15} sx={{ p: 2, minWidth: '500px', maxWidth: '90dvw' }}>
                {sanitizedCouponMessage ? <Typography variant="subtitle1" align="center" component="div" dangerouslySetInnerHTML={{ __html: sanitizedCouponMessage }} /> : null}
            </Paper>
        </Stack>
    );
};

export default observer(CouponMessage);
