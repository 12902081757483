import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";

const useMesaureTime = () => {
    const [startTime, setStartTime] = useState<Dayjs>();

    const startMeasure = () => {
        if(startTime) {
            console.warn('Start time already set');
            return;
        }

        setStartTime(dayjs());
    }

    const endMeasure = (unit: 'second' | 'minute') => {
        if(!startTime) {
            console.error('Start time not set');
            return;
        }

        return dayjs().diff(startTime, unit);
    }

    const reset = () => {
        setStartTime(undefined);
    }

    return {
        startMeasure,
        endMeasure,
        reset
    }
}

export default useMesaureTime;